<template>
  <div class="w-100 mb-4">
    <Header :tabs="false" />
    <Form @saved="loadData" />
  </div>
</template>

<script>
import Header from "../components/Header";
import Form from "../components/Form";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Header,
    Form,
  },
  data: () => ({}),
  computed: {
    ...mapState("strategic_plans", ["strategic_plan"]),
  },
  methods: {
    ...mapActions("strategic_plans", ["ActionSetNew"]),
    loadData(id) {
      this.$router.push({ name: "strategic_plans_edit", params: { id: id } });
    },
  },
  async created() {
    this.ActionSetNew();
  },
};
</script>
